module.exports = {
  assetPrefix: "",
  basePath: "",
  reactStrictMode: true,
  trailingSlash: true,
  images: {
    disableStaticImages: true,
  },
  productionBrowserSourceMaps: true,
  pageExtensions: ["page.js"],
  async rewrites() {
    // uncomment this rewrites method and specify the correct domain to make your localhost connect to the job search or API
    return [
      {
        source: "/_sf/:path*",
        destination: `${process.env.API_URL}/_sf/:path*`,
      },
    ];
  },
  webpack: (config) => {
    config.module.rules.push({
      test: /\.svg$/,
      use: ["@svgr/webpack"],
    });
    config.module.rules.push({
      test: /\.(jpe?g|png|gif|ico|eot|ttf|woff|woff2|mp4|pdf|webm|webp)$/,
      type: "asset",
      generator: {
        filename: "static/chunks/[path][name].[hash][ext]",
      },
    });

    return config;
  },
};
