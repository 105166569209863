import routes from "@/routes.json";
import config from "../../next.config";
import { isUrl } from "@/functions/isUrl";

export const getRoute = (key, params = {}) => {
  let route = getRouteObject(key);

  if (!route) {
    return isUrl(key) ? key : `${config.basePath}${key}`;
  }

  if (isUrl(route)) {
    return route;
  }

  if ("url_slugs" in params) {
    route = `${route.pattern.replace("[url_slugs]", "")}${params.url_slugs.join("/")}/`;
  } else if (Object.keys(params).length > 0) {
    Object.keys(params).forEach((p) => {
      route = route.pattern.replace(`[${p}]`, params[p]);
    });
  } else {
    route = route.url;
  }

  return `${config.basePath}${route}`;
};

export const getRouteObject = (key) => {
  return key in routes ? routes[key] : null;
};

export const getRouteIdArray = (...keys) => {
  return Object.keys(routes)
    .filter((k) => keys.includes(k) && "id" in routes[k])
    .map((k) => routes[k].id);
};
